<template>
  <div class=" wrapper" v-loading.fullscreen.lock="loading" :element-loading-text="$t('connecting')"
    element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="top-header">
        <div class="flex items-center gap-3">
            <button class="bg-white p-1 w-8 h-8 rounded-xl" @click="$router.go(-1)">
                <i class="el-icon-arrow-left"></i>
            </button>
            <h1>{{ $t("object_building_list") }} </h1>
        </div>
    </div>  
    <div class="wp-body obj-list">
      <header class="wp-header">
        <div class="grid grid-cols-12 gap-3 flex-1">
          <div v-if="me.role.name == 'admin'"  class="col-span-2">
            <el-select v-model="buildinggroup" @change="__GET()" :placeholder="$t('building_catagory')" clearable>
              <el-option v-for="item in building_group" :key="item.id" :label="item[`name_${$i18n.locale}`]
                  ? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="col-span-2">
            <el-input v-model="search"  :placeholder="$t('search')"
              prefix-icon="el-icon-search"  @keyup.enter.native="__GET()">
            </el-input>
          </div>
        </div> 
        <button v-if="['operator', 'ministry'].includes(me.role.name )" @click="$router.push({name: 'add-build',params: { id: $route.params.id, obj: $route.params.id },})" 
          class="button dark-yellow">
          <i class="el-icon-plus mr-1"></i> {{ $t("add_building") }}
        </button> 
      </header>

      <el-table :data="buildsList" class="mt-5" style="width: 100%; margin-bottom: 20px;" row-key="id" stripe
        default-expand-all>
        <el-table-column label="№" width="50" align="center">
          <template slot-scope="scope">
            {{ (current_page - 1) * 10 + (scope.$index + 1) }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('building_name')" align="center" sortable>
          <template slot-scope="scope">
            <div class="d-flex justify-center">
              <p @click="routeTo(scope.row, '0')" class="router-link">
                {{ scope.row.name_cyr ? scope.row.name_cyr : "***" }}
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column v-if="me.role.name == 'admin'" prop="cadastral_number" :label="$t('cadaster_number')"
          align="center" sortable>
        </el-table-column>
        <el-table-column :label="$t('building_address')" prop="address" align="center" sortable>
        </el-table-column>
        <el-table-column :label="$t('created_at')" prop="created_at" align="center" sortable>
        </el-table-column>
        <el-table-column :label="$t('year_construction')" prop="year_construction" align="center" sortable>
        </el-table-column>
        <el-table-column prop="finalconclusion" :label="$t('finalconclusion')" align="center" sortable>
          <template slot-scope="scope">
            <p :class="parseInt(scope.row.finalconclusion) > 2
                ? parseInt(scope.row.finalconclusion) == 3
                  ? 'c-yellow'
                  : 'c-red'
                : 'c-green'
              ">
              {{ scope.row.finalconclusion }}
            </p>
          </template>
        </el-table-column>
        <el-table-column :label="$t('buildings_type')" prop="" align="center" sortable>
          <template slot-scope="scope">
            <el-tag :class="buildingtype === 'NEW' ? 'new_build' : 'exist_build'" style="width: 120px; cursor: pointer"
              size="small" :type="buildingtype === 'NEW' ? 'success' : 'info'">{{
                scope.row.buildingtype === "NEW"
                  ? $t("new_building")
                  : $t("existing_building")
              }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column :label="role == 'admin'
            ? $t('passport') + ' / ' + $t('statistic')
            : $t('statistic')
          " width="180" align="center">
          <template slot-scope="scope">
            <div class="flex justify-center">
              <div v-if="role == 'admin'">
                <el-button @click="GeneratePasport(scope.row.id)" class="el-button-download el-icon-s-order">
                </el-button>
              </div>
              <el-button class="ml-2 el-icon-download el-button-download" size="small"
                @click="DownloadExel(scope.row.id, scope.row.name_cyr)">
              </el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column v-if="['operator'].includes(role)" label="Кадастр рақамни янгилаш" width="180" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.updating_cadastr && scope.row.cadastr_list && scope.row.cadastr_list.length"
              class="flex justify-center">
              <button class="px-3 py-2 bg-purple-600 text-white rounded-xl text-xs font-bold"
                @click="setNewCadasterFrom(scope.row)">Янгилаш</button>
            </div>
            <div v-else class="test-green-600 font-bold text-green-600">
              Ўзгармаган
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" :label="role == 'operator'
            ? $t('change') + '/' + $t('delete')
            : $t('change')
          " v-if="role !== 'org_admin' && role !== 'ministry_admin'">
          <template slot-scope="scope">
            <div class="d-flex justify-center" v-if="!(role == 'operator' && scope.row.buildingtype == 'NEW')">
              <el-button @click="routeTo(scope.row, '1')" size="small"
                class="el-icon-edit el-button-download "></el-button>
              <el-button v-if="role == 'operator' && false" @click="deleteBuilding(scope.row.id)" size="small"
                class="el-icon-delete el-button-download  danger"></el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- <el-pagination v-if="page_count" style="margin-left: auto; margin-top: auto" class="ml-auto" @current-change="paginate" :current-page.sync="current_page" :hide-on-single-page="true" background layout="prev, pager, next" :page-count="page_count">
        </el-pagination> -->
      <el-pagination style="margin-left: auto; margin-top: auto" class="ml-auto" :page-sizes="[10, 20, 50, 100]"
        :page-size="5" @current-change="paginate" @size-change="paginateSize" :current-page.sync="current_page"
        :hide-on-single-page="false" background layout="sizes, prev, pager, next" :page-count="page_count">
      </el-pagination>
      <el-drawer :title="$t('building_passport')" :visible.sync="drawer" direction="rtl">
        <div>
          <div class="pdf">
            <vue-pdf ref="print" :src="pdf"></vue-pdf>
            <div class="loading-pdf" v-show="loadDawer">
              <i class="el-icon-loading"></i>
            </div>
          </div>
          <div class="w-full button dark-yellow">
            <button class="print" @click="downloadFile(pdf)">
              <i class="el-icon-download mr-2"></i> {{ $t("download") }}
            </button>
            <a :href="pdf" target="_blank">
              <button class="ml-3 button dark-blue">
                <i class="el-icon-view mr-2"></i> {{ $t("see") }}
              </button>
            </a>
          </div>
        </div>
      </el-drawer>
      <BuildingUpgradeCadasterVue v-model="newCadastrModal" :buildingInfo="newCasasterForm" @changed="__GET()" />
    </div>
  </div>
</template>

<script>
import Moment from "moment";
import { extendMoment } from "moment-range";
import BuildingUpgradeCadasterVue from '@/components/BuildingUpgradeCadaster.vue';
const moment = extendMoment(Moment);
export default {
  components: { BuildingUpgradeCadasterVue },
  data() {
    return {
      input2: null,
      page_count: 0,
      buildsList: [],
      region: "",
      district: "",
      buildinggroup: "",
      current_district: [],
      objInfo: {},
      search: "",
      item_count: 10,
      loading: false,
      current_page: 1,
      buildingtype: "",
      districts: [],
      pdf: "",
      namePdf: "",
      loadDawer: false,
      drawer: false,
      newCadastrModal: false,
      newCasasterForm: {}
    };
  },
  computed: {
    me() {
      return this.$store.state.me;
    },
    role() {
      return this.$store.state.role;
    },
    regions() {
      return this.$store.state.dictionary.region;
    },
    building_group() {
      return this.$store.state.dictionary.buildinggroup;
    },
  },
  mounted() {
    this.__GET();
  },
  methods: {
    GeneratePasport(id) {
      this.loading = true;
      axios
        .post(`/building/specification/${id}/generate/`)
        .then((res) => {
          if (res.data.success) {
            this.drawer = true;
            this.loadDawer = true;
            this.pdf = axios.defaults.baseURL.slice(0, -8) + res.data.pdf_link;
          }
        })
        .catch((e) => { })
        .finally(() => {
          this.loading = false;

          this.loadDawer = false;
        });
    },
    paginate(val) {
      this.current_page = parseInt(val);
      this.__GET();
    },
    paginateSize(val) {
      this.item_count = val;
      this.__GET();
    },
    __GET() {
      if (this.$route.params.id) {
        this.loading = true;
        let offset = this.item_count * (this.current_page - 1);
        axios
          .get(`/building/generalinformation/${this.$route.params.id}/`)
          .then((response) => {
            this.objInfo = response.data;
          });
        if (this.role == "admin") {
          axios
            .get(
              `/building/specificationlist/?list=selectionforadmin&generalinformation=${this.$route.params.id}&region__shared_id=${this.region}&district=${this.district}&building_group=${this.buildinggroup}&search=${this.search}&limit=${this.item_count}&offset=${offset}`
            )
            .then((response) => {
              this.buildsList = response.data.results;
              this.page_count = Math.ceil(
                response.data.count / this.item_count
              );
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          axios
            .get(
              `/building/specificationlist/?generalinformation=${this.$route.params.id}&region__shared_id=${this.region}&district=${this.district}&building_group=${this.buildinggroup}&search=${this.search}&limit=${this.item_count}&offset=${offset}`
            )
            .then((response) => {
              this.buildsList = response.data.results;
              this.page_count = Math.ceil(
                response.data.count / this.item_count
              );
            })
            .finally(() => {
              this.loading = false;
            });
        }
      }
    },
    fetchItem() {
      let offset = this.item_count * (this.current_page - 1);
      if (this.role == "admin") {
        axios
          .get(
            `/building/specificationlist/?list=selectionforadmin&generalinformation=${this.$route.params.id}&region__shared_id=${this.region}&district=${this.district}&building_group=${this.buildinggroup}&search=${this.search}&limit=${this.item_count}&offset=${offset}`
          )
          .then((response) => {
            this.buildsList = response.data.results;
            this.page_count = Math.ceil(response.data.count / this.item_count);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        axios
          .get(
            `/building/specificationlist/?generalinformation=${this.$route.params.id}&region__shared_id=${this.region}&district=${this.district}&building_group=${this.buildinggroup}&search=${this.search}&limit=${this.item_count}&offset=${offset}`
          )
          .then((response) => {
            this.buildsList = response.data.results;
            this.page_count = Math.ceil(response.data.count / this.item_count);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    routeTo(item, a) {
      if (item.buildingtype === "NEW") {
        this.$router
          .push({
            name: "new-build",
            params: {
              obj: this.$route.params.id,
              edit: a,
            },
            query: {
              id: item.id,
            },
          })
          .catch((e) => {
            if (
              e.name !== "NavigationDuplicated" &&
              !e.message.includes(
                "Avoided redundant navigation to current location"
              )
            ) {
              console.log(e);
            }
          });
      } else {
        this.$router
          .push({
            name: "exist-build",
            params: {
              obj: this.$route.params.id,
              edit: a,
            },
            query: {
              id: item.id,
            },
          })
          .catch((e) => {
            if (
              e.name !== "NavigationDuplicated" &&
              !e.message.includes(
                "Avoided redundant navigation to current location"
              )
            ) {
              console.log(e);
            }
          });
      }
    },
    getDistrict() {
      this.loading = true;
      this.__GET();
      axios
        .get(`/dictionary/district/?region__shared_id=${this.region}`)
        .then((response) => {
          this.districts = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    DownloadExel(id, name) {
      axios({
        url: `/building/generate/${id}/building/`,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", `${name}-Bino-${id}.xlsx`);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    deleteBuilding(id) {
      return this.$confirm(`Сиз ростдан ҳам ушбу бинони ўчирмоқчимисиз?`, {
        confirmButtonText: "Ҳа",
        cancelButtonText: "Йўқ",
      }).then(() => {
        axios
          .delete(`/building/buildingdestroy/${id}/`)
          .then((response) => {
            this.$swal.fire({
              icon: "success",
              title: "Қониқарли. Бинони учирилди",
              timer: 3000,
            });
            this.__GET();
          })
          .catch((error) => {
            this.$swal.fire({
              icon: "error",
              title: "Бинони учиришда хатолик",
              timer: 3000,
            });
          });
      });
    },
    downloadFile(file) {
      fetch(file)
        .then((resp) => resp.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          // the filename you want
          a.download = "Pasport" || "file.json";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        });
    },
    setNewCadasterFrom(item) {
      this.newCadastrModal = true
      this.newCasasterForm = item
    }
  },
};
</script>

<style lang="scss" scoped>  
</style>
